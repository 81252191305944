import React from 'react';
import './UserInfo.css';
import DeviceInfo from './DeviceInfo';
import { formatDate } from './Utils';

const alphaLink = (account) => {
  return `https://alpha.seeyou.cloud/users/${account.id}`;
};

const myAccountLink = (account) => {
  return `https://myaccount.seeyou.cloud/backend?q=${account.email}`;
};

const UserInfo = ({ data }) => {
  return (
    <div className="user-info">
      <p className="user-name"><strong>{data.firstName} {data.lastName}</strong></p>
      <ul className="account-list">
        {data.accounts.map((account, index) => (
          <li key={index} className="account-item">
            <p className="account-detail meta"><a href={myAccountLink(account)} target="_blank" rel="noreferrer" className="email-value">{account.first_name} {account.last_name}</a></p>
            <p className="account-detail meta"><strong>ID:</strong> <a href={alphaLink(account)} target="_blank" rel="noreferrer" className="email-value">{account.id}</a></p>
            <p className="account-detail meta"><strong>Email:</strong> <a href={`mailto:${account.email}`} className="email-value">{account.email}</a></p>
            {account.subscription && <p className="account-detail meta"><strong>Subscription until </strong> {formatDate(account.subscription)}</p>}
            {account.devices && account.devices.length > 0 && <DeviceInfo devices={account.devices} />}
            {account.has_glider_in_hangar && <p className="account-detail meta"><strong>Glider pilot</strong></p>}
            {account.has_paraglider_in_hangar && <p className="account-detail meta"><strong>Paraglider pilot</strong></p>}
            {account.has_hangglider_in_hangar && <p className="account-detail meta"><strong>Hang-glider pilot</strong></p>}
            {account.has_glider_in_hangar === undefined && account.has_paraglider_in_hangar === undefined && account.has_hangglider_in_hangar === undefined && <p className="account-detail meta"><strong>Sport unknown</strong></p>}
            <p className="account-detail meta"><strong>Flights 12m:</strong> {account.flights_last_year}</p>
            <p className="account-detail meta"><strong>Hours 12m:</strong> {account.hours_last_year}</p>
          </li>
        ))}
      </ul>
      {data.accounts.length === 0 && <p className="account-detail meta"><strong>Doesn't have a SeeYou Account</strong></p>}
    </div>
  );
};

export default UserInfo;
